import Stack from '@mui/material/Stack';
import Img from 'react-app/components/Img';

export default function HeroBannerLogo(props) {
	const {
		children,
		ImgProps,
		LogoProps,
		...rest
	} = props;

	return (
		ImgProps ? (
			<Stack
				marginY="auto"
				marginX={(theme => theme.config.gutterSpacing)}
				direction={{md: 'row'}}
				gap={{xs: 1, phone: 2}}
				{...rest}
			>
				<Stack
					width="auto"
					marginY="auto"
					alignSelf="center"
					{...LogoProps}
				>
					<Img
						fullWidth
						component={Stack}
						alignItems="center"
						direction="row"
						{...ImgProps}
					/>
				</Stack>
				{children}
			</Stack>
		) : children
	);
}

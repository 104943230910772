import { createClasses } from 'styles';
import Button from 'components/Button';
import { useBrowser } from 'react-app/context/browser';
import { useDialog } from 'react-app/components/DialogProvider';
import { useDeferredUser } from 'context/user';

const useClasses = createClasses((theme, { gutterBottom }) => ({
	root: {
		margin: 'auto',
		display: 'flex',
		alignSelf: 'center',
		...(gutterBottom && {
			marginBottom: theme.config.gutterSpacing
		})
	}
}), {
	name: 'HeroBannerButton'
});

export default function HeroBannerButton(props) {
	const { title, onClick, href, color, ...rest } = props;
	const classes = useClasses(props);
	const { openDialog } = useDialog();

	const { isWidthUp, breakpoint } = useBrowser();
	const smUp = isWidthUp('sm', breakpoint);
	const mdUp = isWidthUp('md', breakpoint);
	const desktopUp = isWidthUp('desktop', breakpoint);
	const [ {isRetail} ] = useDeferredUser();

	return (
		<Button
			variant="solid"
			color={color || (isRetail ? 'hw-orange' : 'hw-green')}
			{...rest}
			size={desktopUp ? 'huge' : mdUp ? 'large' : smUp ? 'medium' : 'small'}
			className={classes.root}
			{...(onClick === 'openPageDialog') ? {
				onClick: openDialog,
				'data-title': title,
				'data-src': href
			} : {
				href,
				onClick
			}}
		>
			{props.children || 'mehr erfahren'}
		</Button>
	);
}

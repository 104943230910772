/* global WEBPACK_IS_PUBLIC */

import { createClasses } from 'styles';
import Box from '@mui/material/Box';
import Img, { useImageSrc } from 'react-app/components/Img';
import Headline from './HeroBannerHeadline';
import Button from './HeroBannerButton';

const useClasses = createClasses((theme, {overlay, disableGuttersX, disableGutterBottom, disableBorderRadius, imagePosition}) => ({
	root: {
		display: 'flex',
		position: 'relative',
		width: '100%',
		height: '100%',
		maxHeight: {xs: 180, sm: 200, tablet: 220, desktop: 250},
		minHeight: {xs: 180, sm: 200, tablet: 220, desktop: 250},
		maxWidth: theme.config.containerMaxWidth,
		...(!disableGuttersX && {
			paddingX: theme.config.containerSpacing
		}),
		...(!disableGutterBottom && {
			marginBottom: theme.config.containerSpacing
		})
	},
	container: {
		top: 0,
		left: 0,
		position: 'absolute',
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		maxWidth: '100%',
		padding: theme.config.gutterSpacing
	},
	image: {
		position: 'relative',
		'&, & > img': {
			objectFit: 'cover',
			objectPosition: imagePosition || 'initial',
			width: '100%'
		},
		...(!disableBorderRadius && {
			'&, &:before, & > img': {
				borderRadius: `${theme.shape.borderRadius}px`
			}
		}),
		...(overlay && {
			'&:before': {
				top: 0,
				left: 0,
				position: 'absolute',
				width: '100%',
				height: '100%',
				content: '""',
				background: 'linear-gradient(to bottom, rgba(0,0,0,.3) 0%, rgba(0,0,0,.15) 25%, transparent 100%)'
			}
		})
	}
}), {
	name: 'HeroBanner'
});

export default function HeroBanner(props) {
	const {
		classes: classesProp,
		children,
		title,
		subtitle,
		href,
		image = {},
		imagePosition,
		ImgProps,
		HeadingProps,
		SubheadingProps,
		ContainerProps,
		ButtonProps,
		disableGuttersX,
		disableGutterBottom,
		disableBorderRadius,
		overlay,
		buttonTitle = 'mehr erfahren',
		...rest
	} = props;

	const classes = useClasses(props);
	const { width, height, src } = useImageSrc(null, image) || {};

	if (WEBPACK_IS_PUBLIC && !src) {
		return null;
	}

	const Content = ({children}) => src ? (
		<Img
			showPlaceholder
			{...ImgProps}
			width={width}
			height={height}
			src={src}
			className={classes.image}
		>
			{children}
		</Img>
	) : (
		<Box
			{...ImgProps}
			width={width}
			height={height}
			className={classes.image}
		>
			{children}
		</Box>
	);

	return (
		<Box className={classes.root} {...rest}>
			<Content>
				<Box {...ContainerProps} className={classes.container}>
					{(title || subtitle) && (
						<Headline
							title={title}
							subtitle={subtitle}
							{...HeadingProps}
							SubheadingProps={SubheadingProps}
						/>
					)}
					{href && (
						<Button {...ButtonProps} href={href}>
							{buttonTitle}
						</Button>
					)}
					{children}
				</Box>
			</Content>
		</Box>
	);
}

import { createClasses } from 'styles';
import Headline from 'components/Headline';
import { hwGreen } from 'styles/colors';

const useClasses = createClasses((theme, {noTextStroke, gutterTop = true, gutterBottom, color}) => ({
	root: {
		...theme.mixins.typography.heading,
		color: '#fff' || color,
		...(!noTextStroke && {
			color: color || hwGreen[700],
			// WebkitTextStrokeColor: '#fff',
			// WebkitTextStrokeWidth: { xs: 1, sm: 1.5, md: 2 },
			letterSpacing: 1.25,
			...theme.mixins.textStroke(1.5),
			'&&': {
				[theme.breakpoints.up('md')]: {
					letterSpacing: 1,
					...theme.mixins.textStroke(2.5)
				}
			}
		}),
		alignSelf: 'center',
		textAlign: 'center',
		fontWeight: theme.typography.fontWeightBold,
		textTransform: 'uppercase',
		gap: theme.config.gutterSpacing,
		...(gutterTop && {
			marginTop: {
				xs: 1,
				lg: 0
			}
		}),
		...(gutterBottom && {
			marginBottom: theme.config.containerSpacing
		}),
		fontSize: {
			xs: theme.typography.pxToRem(18),
			sm: theme.typography.pxToRem(24),
			md: theme.typography.pxToRem(32),
			xl: theme.typography.pxToRem(44)
		}
	},
	subheading: {
		...theme.mixins.typography.subheading,
		color: 'inherit',
		...(!noTextStroke && {
			// WebkitTextStrokeColor: 'inherit',
			// WebkitTextStrokeWidth: { xs: .75, sm: 1, md: 1.25 },
			letterSpacing: 1,
			...theme.mixins.textStroke(1),
			'&&': {
				[theme.breakpoints.up('md')]: {
					letterSpacing: .5,
					...theme.mixins.textStroke(1.5)
				}
			}
		}),
		fontWeight: 'inherit',
		marginTop: {xs: 1, md: 0},
		fontSize: {
			xs: theme.typography.pxToRem(14),
			sm: theme.typography.pxToRem(18),
			md: theme.typography.pxToRem(24),
			xl: theme.typography.pxToRem(28)
		}
	}
}), {
	name: 'HeroBannerHeadline'
});

export default function HeroBannerHeadline(props) {
	const { noTextStroke, gutterTop, ...rest } = props;
	const classes = useClasses(props);

	return (
		<Headline
			component="h2"
			{...rest}
			classes={{
				...props?.classes,
				root: classes.root,
				subheading: classes.subheading
			}}
		/>
	);
}

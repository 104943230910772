import * as React from 'react';
import { createClasses } from 'styles';
import ScrollableCards from 'components/ScrollableCards';
import HeroBanner, { HeroBannerHeadline, HeroBannerButton } from './';
import * as images from 'config/images';

const useClasses = createClasses((theme, {disableGutterBottom, fullWidth}) => ({
	root: {
		...(!fullWidth && {
			maxWidth: theme.config.containerMaxWidth,
			paddingX: theme.config.containerSpacing
		}),
		...(!disableGutterBottom && {
			marginBottom: theme.config.containerSpacing
		})
	},
	slide: {
		alignItems: 'center',
		maxWidth: theme.config.containerMaxWidth
	},
	track: {
		...(!fullWidth && {
			borderRadius: `${theme.shape.borderRadius}px`
		})
	},
	navDots: {
		zIndex: 100,
		position: 'absolute',
		left: 0,
		bottom: theme.spacing(1.5),
		color: '#fff'
	}
}), {
	name: 'HeroBannerCarousel'
});

function HeroBannerCarousel(props) {
	const {
		children,
		slides: slidesProp = [],
		HeroBannerComponent,
		HeroBannerProps,
		HeroBannerHeadlineProps,
		HeroBannerButtonProps,
		disableGutterBottom,
		disableGuttersX,
		fullWidth,
		...rest
	} = props;

	const classes = useClasses(props);

	const hasMultipleSlides = slidesProp.length > 1;

	const slides = children ? children : (
		Array.isArray(slidesProp) && slidesProp.map((slide = {}, key) => {
			const { id, imageId, title, subtitle, href, onClick, buttonTitle, buttonColor } = slide;
			const image = slide.image || images[imageId || `${id}_banner`] || images[id];

			return HeroBannerComponent ? <HeroBannerComponent key={key} {...HeroBannerProps}/> : (
				<HeroBanner
					key={key}
					image={image}
					{...HeroBannerProps}
					{...slide.HeroBannerProps}
					disableGuttersX={!fullWidth && hasMultipleSlides}
					disableBorderRadius={!fullWidth && hasMultipleSlides}
					disableGutterBottom={hasMultipleSlides}
				>
					{(title || subtitle) && (
						<HeroBannerHeadline
							title={title}
							subtitle={subtitle}
							{...HeroBannerHeadlineProps}
							{...slide.HeroBannerHeadlineProps}
						/>
					)}
					{href && (
						<HeroBannerButton
							href={href}
							title={title}
							color={buttonColor}
							onClick={onClick}
							{...HeroBannerButtonProps}
							{...slide.HeroBannerButtonProps}
						>
							{buttonTitle}
						</HeroBannerButton>
					)}
				</HeroBanner>
			);
		})
	);

	return (
		hasMultipleSlides ? (
			<ScrollableCards
				disableGutterBottom
				showNavDots
				{...rest}
				fullWidth
				navButtonOffsetXFullWidth={fullWidth}
				classes={{
					root: classes.root,
					track: classes.track,
					slide: classes.slide,
					navDots: classes.navDots
				}}
			>
				{slides}
			</ScrollableCards>
		) : slides
	);
}

export default React.memo(HeroBannerCarousel);

import * as React from 'react';
import { useTheme } from 'styles';
import { createClasses } from 'styles';
import ScrollableCardsBase from 'react-app/components/ScrollableCards';
import ScrollableViewsBase from 'react-app/components/ScrollableViews';
import Box from '@mui/material/Box';
import { appData } from 'config/data';

const { isCzech, isFactory } = appData;

const useClasses = createClasses((theme, {disableGutterBottom, spacingType = 'gutter'}) => ({
	root: {
		...(!disableGutterBottom && ({
			'&:not(:last-child):not(:empty)': {
				marginBottom: theme.config[`${spacingType}Spacing`]
			}
		}))
	}
}), {
	name: 'ScrollableCards'
});

function ScrollableCards(props) {
	const theme = useTheme();
	const classes = useClasses(props);

	const {
		className,
		children,
		cards: cardsProp = [],
		CardProps,
		CardComponent = 'div',
		disableGutterBottom,
		fullWidth,
		trackOffset,
		navButtonOffsetX,
		navButtonOffsetXFullWidth = isCzech || isFactory,
		...rest
	} = props;

	const ScrollableComponent = fullWidth ? ScrollableViewsBase : ScrollableCardsBase;

	const cards = children ? children : (
		Array.isArray(cardsProp) && cardsProp.map((card, key) => (
			<CardComponent
				key={key}
				{...card}
				{...CardProps}
				{...(CardProps?.preload && key === 0
					? { preload: true }
					: null
				)}
			/>
		))
	);

	return (
		<ScrollableComponent
			component={Box}
			{...rest}
			fullWidth={fullWidth}
			className={classes.root}
			{...(!fullWidth && {
				trackOffset: {
					desktop: `max(calc((100% - ${theme.breakpoints.values.desktop}px) / 2), ${theme.spacing(4)})`,
					...trackOffset
				}
			})}
			navButtonOffsetX={navButtonOffsetXFullWidth ? {
				desktop: `max(calc((100% - ${theme.config.containerMaxWidth}px) / 2) - ${theme.spacing(1.5)}, ${theme.spacing(-1.5)})`,
				...navButtonOffsetX
			} : {
				xs: 0,
				md: `max(calc((100% - ${theme.config.containerMaxWidth}px) / 2) - ${theme.spacing(2.5)}, ${theme.spacing(-2.5)})`,
				lg: `max(calc((100% - ${theme.config.containerMaxWidth}px) / 2) - ${theme.spacing(1.5)}, ${theme.spacing(-1.5)})`,
				desktop: `max(calc((100% - ${theme.config.containerMaxWidth}px) / 2) - ${theme.spacing(5.5)}, ${theme.spacing(-5.5)})`,
				...navButtonOffsetX
			}}
			NavButtonProps={{
				flat: false
			}}
		>
			{cards}
		</ScrollableComponent>
	);
}

export default React.memo(ScrollableCards);

import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses, clsx, gutterSpacing } from '../../styles';
import ScrollableViews from '../ScrollableViews';

const defaultProps = {
	gap: {...gutterSpacing}
};

const useClasses = createClasses((theme, {
	cardSizes,
	cardsToShow,
	expandable,
	maxWidth,
	trackOffset,
	gap = defaultProps.gap,
	centered,
	disableCardMaxWidth
}) => ({
	root: {
		...(maxWidth && {
			margin: 'auto',
			maxWidth
		})
	},
	track: {
		gap,
		paddingX: theme.config.containerSpacing,
		scrollPadding: Object.assign({}, ...Object.entries(theme.config.containerSpacing).map(([key, width]) => ({
			[key]: theme.spacing(width)
		}))),
		...(expandable && {
			alignItems: 'flex-start'
		}),
		'&&': {
			...(trackOffset && Object.assign({}, ...Object.entries(trackOffset).map(([key, offset]) => ({
				[theme.breakpoints.up(key)]: {
					paddingX: theme.spacing(offset),
					scrollPadding: theme.spacing(offset)
				}
			}))))
		}
	},
	slide: {
		flexDirection: 'row',
		...(centered && {
			'&:first-child': {
				marginLeft: 'auto'
			},
			'&:last-child': {
				marginRight: 'auto'
			}
		}),
		...(cardSizes ? Object.assign({}, ...Object.entries(cardSizes).map(([key, width]) => ({
			[theme.breakpoints.up(key)]: {
				minWidth: width,
				...(!disableCardMaxWidth && {maxWidth: width})
			}
		}))) : {
			minWidth: 'clamp(280px, 360px, 90%)'
		}),
		...(cardsToShow ? {
			scrollSnapAlign: 'center',
			justifyItems: 'center',
			flexDirection: 'column',
			...(Object.assign({}, ...Object.entries(cardsToShow).map(([key, count]) => ({
				[theme.breakpoints.up(key)]: {
					minWidth: `${100 / count}%`
				}
			}))))
		} : {
			[theme.breakpoints.down('sm')]: {
				scrollSnapAlign: 'center'
			}
		})
	}
}), {
	name: 'ScrollableCards'
});

function ScrollableCards(props) {
	const {
		classes: classesProp,
		className,
		children,
		CarouselClasses,
		trackOffset,
		cardSizes,
		cardsToShow,
		expandable,
		maxWidth,
		gap = defaultProps.gap,
		disableCardMaxWidth,
		...rest
	} = props;

	const classes = useClasses(props);

	return (
		<ScrollableViews
			{...rest}
			classes={{
				...CarouselClasses,
				root: classes.root,
				slide: clsx(
					classes.slide,
					CarouselClasses?.slide
				),
				track: clsx(
					classes.track,
					CarouselClasses?.track
				)
			}}
		>
			{children}
		</ScrollableViews>
	);
}

ScrollableCards.propTypes = {
	children: PropTypes.node,
	trackOffset: PropTypes.object,
	cardSizes: PropTypes.object,
	cardsToShow: PropTypes.object,
	CardProps: PropTypes.object,
	CarouselClasses: PropTypes.object,
	expandable: PropTypes.bool,
	gap: PropTypes.object,
	maxWidth: PropTypes.number,
	disableCardMaxWidth: PropTypes.bool
};

export default React.memo(ScrollableCards);

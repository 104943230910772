import * as React from 'react';
import PropTypes from 'prop-types';
import { createClasses } from '../../styles';
import { Container as  MuiContainer } from '@mui/material';

const defaultProps = {
	fullWidth: false,
	maxWidth: false,
	stretch: false,
	flex: false
};

const useClasses = createClasses((theme, {
	fullWidth = defaultProps.fullWidth,
	stretch = defaultProps.stretch,
	flex = defaultProps.flex
}) => ({
	root: {
		width: '100%',
		display: 'flex',
		flexWrap: 'wrap',
		alignItems: 'flex-start',
		margin: '0 auto',
		...(flex && {
			...theme.mixins.flexContainer
		}),
		...(stretch && {
			'&&': {
				alignItems: 'stretch'
			}
		}),
		...(fullWidth && {
			'&&': {
				maxWidth: 'none'
			}
		})
	}
}), {
	name: 'RaContainer'
});

function Container(props) {
	const {
		classes: classesProp,
		className,
		children,
		stretch = defaultProps.stretch,
		fullWidth = defaultProps.fullWidth,
		flex = defaultProps.flex,
		maxWidth = defaultProps.maxWidth,
		...rest
	} = props;

	const classes = useClasses(props);

	return (
		<MuiContainer
			{...rest}
			{...(!fullWidth && maxWidth && Number.isFinite(maxWidth) ? {
				sx: {'&&': {maxWidth}, ...props.sx}
			} : {
				maxWidth
			})}
			className={classes.root}
		>
			{children}
		</MuiContainer>
	);
}

Container.propTypes = {
	classes: PropTypes.object,
	className: PropTypes.string,
	children: PropTypes.node,
	stretch: PropTypes.bool,
	fullWidth: PropTypes.bool
};

export default React.memo(Container);
